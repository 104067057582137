// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/LogIn.vue';
import MainPage from '@/views/MainPage.vue';

const routes = [
    {
        path: '/',
        name: 'MainPage',
        component: MainPage
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
