<template>
    <div class="sig-0 signup">
        <div class="sig-1"> 
            <!-- <div class="signup"></div> -->
            <!-- <div id="login_container" style="height: 900px; width: 1px; z-index:2000" ></div> -->
            <div class="login-field ">
                <el-form ref="ruleFormRef" :model="ruleForm" class="demo-ruleForm" :rules="rules" status-icon>
                    <div class="title">用户登录</div>
                    <el-form-item prop="phone">
                    <el-input v-model="ruleForm.phone" placeholder="请输入账号" clearable />
                    </el-form-item>
                    <el-form-item prop="password">
                    <el-input v-model="ruleForm.password" placeholder="请输入密码" type="password" show-password clearable
                        @keyup.enter="loginFunc()" />
                    </el-form-item>
                    <el-form-item>
                    <el-button type="primary" class="save-btn" @click="loginFunc()">
                        <span>登录</span>
                    </el-button>
                    </el-form-item>
                    <span class="register">如需注册请联系您的客户经理</span>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script  setup>
import { ref, reactive} from 'vue'
// import type { FormInstance, FormRules } from 'element-plus'
import { ElMessage } from 'element-plus'

const ruleFormRef = ref('')

const rules = reactive({
  phone: [
    { required: true, message: '请输入账户', trigger: 'blur' },
    { min: 11, max: 11, message: '账号不正确', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
  ],
})

const ruleForm = reactive({
    phone: '',
    password: '',
})

const loginFunc = () => {
    ElMessage({
        message: '账号不存在，请联系客户经理开通！',
        type: 'warning',
        plain: true,
    })
}

</script>


<style scoped lang="scss">

@import "./scss/index";
@import "./scss/login";
.signup {
  position: relative;
  background-image: url("@/assets/images/background/pic_v3_副本.png");
//   height: 100%;
//   width: 100%;
  background-size: cover;
  background-position: right;
}



</style>