<template>
  <div class="main-feild" >
    <div class="header s-0"  v-show="curr_tab.color!=='白' ||(curr_tab.color=='白' && scroll_top>=1)"  >
      <div class="nav-logo">
        <img src="@/assets/images/icon/u91.png" alt="Logo" class="logo-0"/>
      </div>
      <div class="nav">
        <my-tabs :tabs="tabs" :current_tab_="curr_tab" :scroll_top="scroll_top" @update-data="handleUpdateData">
        </my-tabs>
      </div>
      <div class="function-area" @click="toLogin">
        <div class="font-0">登录</div>
      </div>
    </div>
    <div class="body" >
      <div class="b-0" v-if="curr_tab.name=='tab1'">
        <div id="b-p-0">
          <div class="top-0" :style="computedStyle">
            <div class="header s-1"  v-show="curr_tab.color=='白' && scroll_top < 1" >
              <div class="nav-logo">
                <img src="@/assets/images/icon/切片_白.png" alt="Logo" class="logo-1"/>
              </div>
              <div class="nav">
                <my-tabs :tabs="tabs"  :current_tab_="curr_tab" :scroll_top="scroll_top" @update-data="handleUpdateData">
                </my-tabs>
              </div>
              <div class="function-area" @click="toLogin">
                <div class="font-1">登录</div>
              </div>
            </div>
            <div id="b-part-0" >
              <section class="sect-left">
                <h2 class="title-1"><span>一站式跨境电商ERP</span></h2>
                <!-- <p class="font-1">
                  <span>以亚马逊平台为主的跨境电商平台</span>
                  <span>集成海外仓即物流服务商系统，自动完成发货全流程</span>
                </p> -->
                <ul class="font-1">
                  <li>以亚马逊平台为主的跨境电商平台</li>
                  <li>集成海外仓即物流服务商系统，自动完成发货全流程</li>
                </ul>
                <div>            
                  <el-button type="primary" size="large" id="bu-0"  @click="toLogin">立即使用</el-button>
                </div>
              </section>
              <section class="sect-right">
                <img src="@/assets/images/background/电脑.png" alt="Logo" class="logo"/>
              </section>
            </div>
          </div>
        </div>
        <div id="b-p-1">
          <div id="b-part-1" >
            <div class="center-title">
              <div class="center1-top">
                货品进销存，一个ERP全搞定
              </div>
              <div class="center1-top1">
                快速开启、自动同步数据、打通全渠道管理
              </div>
            </div>
            <div class = "center1-1">
              <ul class="center1-header">
                  <li class="fist">
                    <img src="@/assets/images/intro/shop.png" alt="" class="liu-img">
                    <div>快速开店，免费绑定店铺</div>
                  </li>
                  <div class="jiantou">
                    <img src="@/assets/images/intro/arrows.png" alt="">
                  </div>
                  <li>
                    <img src="@/assets/images/intro/order-capture.png" alt="" class="liu-img">
                    <div>系统获取订单</div>
                  </li>
                  <div class="jiantou">
                    <img src="@/assets/images/intro/arrows.png" alt="">
                  </div>
                  <li>
                    <img src="@/assets/images/intro/review-order.png" alt="" class="liu-img">
                    <div>自动审核订单</div>
                  </li>
                  <div class="jiantou">
                    <img src="@/assets/images/intro/arrows.png" alt="">
                  </div>
                  <li>
                    <img src="@/assets/images/intro/order-dispatching.png" alt="" class="liu-img">
                    <div>一键分组分派订单</div>
                  </li>
                  <div class="jiantou2">
                    <img src="@/assets/images/intro/arrows2.png" alt="">
                  </div>
                  <li style="margin-left: 65px;">
                    <img src="@/assets/images/intro/print-shipping.png" alt="" class="liu-img">
                    <div>订单打印、发货</div>
                  </li>
                  <div class="jiantou" style="margin-left: 20px;">
                    <img src="@/assets/images/intro/arrows3.png" alt="">
                  </div>
                  <li>
                    <img src="@/assets/images/intro/shipment-order.png" alt="" class="liu-img">
                    <div>生成发货订单</div>
                  </li>
                  <div class="jiantou">
                    <img src="@/assets/images/intro/arrows3.png" alt="">
                  </div>
                  <li>
                    <img src="@/assets/images/intro/check-order.png" alt="" class="liu-img">
                    <div>审核校验订单</div>
                  </li>
                  <div class="jiantou">
                    <img src="@/assets/images/intro/arrows3.png" alt="">
                  </div>
                  <li>
                    <img src="@/assets/images/intro/logistics-distribution.png" alt="" class="liu-img">
                    <div>仓库物流分配</div>
                  </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="b-p-2">
          <div class="center-title">
            <div class="center1-top">
              核心功能
            </div>
            <div class="center1-top1">
              打通跨境电商业务全流程，闭环管理业务，实现业绩增长
            </div>
          </div>
          <div class = "center1-1">
            <ul class="center1-header">
              <li >
                <div  class=" u17" data-left="325" data-top="1867" data-width="307" data-height="229">
                  <div  class="u18 ax_default _文本段落">
                    <div  class=" u18_div">
                      <img  class="u19_img" src="@/assets/images/intro/品牌管理.png">
                    </div>
                    <div  class="text ">
                      <p style="font-size:30px;"><span style="text-decoration:none;">品牌精细管理</span></p>
                      <p style="font-size:12px;"> <span style="text-decoration:none;"><br></span></p>
                        <p style="font-size:14px;"> <span style="text-decoration:none;">确定品牌定位形象，丰富品牌内容，多渠道品牌传播，助力品牌精细化营销</span></p>
                    </div>
                  </div>
                </div>
              </li>
              <li >
                <div  class=" u17" data-left="325" data-top="1867" data-width="307" data-height="229">
                  <div  class="u18 ax_default _文本段落">
                    <div  class=" u18_div">
                      <img  class="u19_img" src="@/assets/images/intro/进销存.png">
                    </div>
                    <div  class="text ">
                      <p style="font-size:30px;"><span style="text-decoration:none;">进销存一体管理</span></p>
                      <p style="font-size:12px;"> <span style="text-decoration:none;"><br></span></p>
                        <p style="font-size:14px;"> <span style="text-decoration:none;">打通订单、采购、发货、物流等环节，提供智能提供补货建议</span></p>
                    </div>
                  </div>
                </div>
              </li>
              <li >
                <div  class=" u17" data-left="325" data-top="1867" data-width="307" data-height="229">
                  <div  class="u18 ax_default _文本段落">
                    <div  class=" u18_div">
                      <img  class="u19_img" src="@/assets/images/intro/数据报表.png">
                    </div>
                    <div  class="text ">
                      <p style="font-size:30px;"><span style="text-decoration:none;">多维度数据报表</span></p>
                      <p style="font-size:12px;"> <span style="text-decoration:none;"><br></span></p>
                        <p style="font-size:14px;"> <span style="text-decoration:none;">自动形成利润报表、采购报表、库存报表、业绩报表等，数据可视化</span></p>
                    </div>
                  </div>
                </div>
              </li>
              <li  class="margin-0">
                <div  class=" u17" data-left="325" data-top="1867" data-width="307" data-height="229">
                  <div  class="u18 ax_default _文本段落">
                    <div  class=" u18_div">
                      <img  class="u19_img" src="@/assets/images/intro/财务管理.png">
                    </div>
                    <div  class="text ">
                      <p style="font-size:30px;"><span style="text-decoration:none;">财务精准管理</span></p>
                      <p style="font-size:12px;"> <span style="text-decoration:none;"><br></span></p>
                        <p style="font-size:14px;"> <span style="text-decoration:none;">每日实时利润统计，订单级回款跟踪，自动提现，业财一体化管理</span></p>
                    </div>
                  </div>
                </div>
              </li>
              <li class="margin-0">
                <div  class=" u17" data-left="325" data-top="1867" data-width="307" data-height="229">
                  <div  class="u18 ax_default _文本段落">
                    <div  class=" u18_div">
                      <img  class="u19_img" src="@/assets/images/intro/巨量广告.png">
                    </div>
                    <div  class="text ">
                      <p style="font-size:30px;"><span style="text-decoration:none;">全流程广告管理</span></p>
                      <p style="font-size:12px;"> <span style="text-decoration:none;"><br></span></p>
                        <p style="font-size:14px;"> <span style="text-decoration:none;">投放前挖掘优质词，投中使用自动规则提效，投后溯源&分析广告转化率</span></p>
                    </div>
                  </div>
                </div>
              </li>
              <li class="margin-0">
                <div  class=" u17" data-left="325" data-top="1867" data-width="307" data-height="229">
                  <div  class="u18 ax_default _文本段落">
                    <div  class=" u18_div">
                      <img  class="u19_img" src="@/assets/images/intro/运营工具.png">
                    </div>
                    <div  class="text ">
                      <p style="font-size:30px;"><span style="text-decoration:none;">高效运营工具</span></p>
                      <p style="font-size:12px;"> <span style="text-decoration:none;"><br></span></p>
                        <p style="font-size:14px;"> <span style="text-decoration:none;">实时监控关键词排名、竞品监控、ChatGPT文案等工具，辅助运营决策</span></p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="b-p-3">
          <div class="s-body">
            <div class="s-left left-top-2">
              <h2 class="title-2"><span>品牌精细管理</span></h2>
              <div>确定品牌定位形象，丰富品牌内容，多渠道品牌传播，助力品牌精细化运营。</div>
              <ul class="font-2">
                <li>
                  <p>提升品牌调性</p>
                </li>
                <p class="font-3">完善的品牌VI设计，并通过工具化装修，提升整个品牌的调性</p>
                <li>
                  <p>品牌内容丰富</p>
                </li>
                <p  class="font-3">内含丰富的品牌宣传内容，一键生成软文，专业人士远程帮助产出内容</p>
                <li>
                  <p> 精细化运营</p><br/>
                </li>
                <p  class="font-3">定义标准化、场景化运营方案，提供自动化工具，支持主流网站多渠道推广</p>
                <li>
                  <p> 数据安全不共享</p><br/>
                </li>
                <p  class="font-3">数据私有化存储，不共享不售卖，保证数据安全性</p>
              </ul>
            </div>
            <div class="s-right">
              <img  class="u19_img0" src="@/assets/images/intro/切片2.png">
            </div>
          </div>
        </div>
        <div class="b-p-4">
          <div class="s-body">
            <div class="s-left left-top-1">
              <img  class="u19_img0" src="@/assets/images/intro/ABA关键词.png">
            </div>
            <div class="s-right">
              <h2 class="title-2"><span>搜索关键词分析</span></h2>
              <div>关键词搜索频率、商品、趋势排名分析，提供关键词投放参考</div>
              <ul class="font-2">
                <li>
                  <p>预估市场容量</p>
                </li>
                <p class="font-3">搜索相关品类大词，预估市场容量</p>
                <li>
                  <p>定位特定品牌名，查看特定ASIN 表现</p>
                </li>
                <p  class="font-3">看排名找热词；看Click Share 分析流量大小；看转化系数分析转化水平</p>
                <li>
                  <p> Keywords排名监测</p>
                </li>
                <p  class="font-3">特定品类搜索词，商品查看排名趋势变化</p>
              </ul>
            </div>
          </div>
        </div>
        <div class="b-p-3">
          <div class="s-body">
            <div class="s-left left-top-1">
              <h2 class="title-2"><span>进销存一体管理</span></h2>
              <div>打通订单、采购、发货、物流等环节，提供智能提供补货建议</div>
              <ul class="font-2">
                <li>
                  <p>智能补货建议</p>
                </li>
                <p class="font-3">自动追踪全球销量与库存情况，区分物流渠道运输时效和费用提供补货方案</p>
                <li>
                  物流全链条追踪管理
                </li>
                <p  class="font-3">接海外仓,快递系统，多维度库存同步，推仓跟单自动化处理。</p>
                <li>
                  <p>自动化处理</p><br/>
                </li>
                <p  class="font-3">多平台、多店铺订单一键获取；订单全流程自动处理智能识别异常订单，随时进行人工干预</p>
              </ul>
            </div>
            <div class="s-right">
              <img  class="u19_img" src="@/assets/images/intro/19b665168f3f26110b985dbb1aebc6ec.png">
            </div>
          </div>
        </div>
        <div class="b-p-4">
          <div class="s-body">
            <div class="s-left left-top-1">
              <img  class="u19_img" src="@/assets/images/intro/179f93e9bb616976c81c66c50c5c8e9a.png">
            </div>
            <div class="s-right">
              <h2 class="title-2"><span>财务精准管理</span></h2>
              <div>每日实时利润统计，订单级回款跟踪，自动提现，业财一体化管理</div>
              <ul class="font-2">
                <li>
                  <p>实时看利润</p>
                </li>
                <p class="font-3">精准核算利润，实时整合提供利润报表，反哺销售决策调整</p>
                <li>
                  <p>成本严控制</p>
                </li>
                <p  class="font-3">业务成本一目了然，隐形费用自动识别，精准统计所有成本</p>
                <li>
                  <p>资金流追踪</p>
                </li>
                <p  class="font-3">打通“下单-付款-发货-结算-回款”全链路，自动跟踪每一笔订单的回款</p>
              </ul>
            </div>
          </div>
        </div>
        <div class="b-p-3">
          <div class="s-body">
            <div class="s-left left-top-1">
              <h2 class="title-2"><span>多维度数据报表</span></h2>
              <div>自动形成利润报表、采购报表、库存报表、业绩报表等，数据可视化</div>
              <ul class="font-2">
                <li>
                  <p>利润报表</p>
                </li>
                <p class="font-3">实时整合平台数据，精准核算利润，每日出具利润报表，支撑财务核算所需</p>
                <li>
                  <p>产品表现报表</p>
                </li>
                <p  class="font-3">整合Listing所有运营数据，支持同比、环比分析，快速识别爆款产品</p>
                <li>
                  <p>BI自定义数据分析</p><br/>
                </li>
                <p  class="font-3">自定义经营数据驾驶舱，实时监测业务数据，可联动分析，了解业务实况</p>
              </ul>
            </div>
            <div class="s-right">
              <img  class="u19_img" src="@/assets/images/intro/9dad0ebbfacce4596312729410020888.png">
            </div>
          </div>
        </div>
        <div class="b-p-4">
          <div class="s-body">
            <div class="s-left left-top-1">
              <img  class="u19_img" src="@/assets/images/intro/0433743a2e7300e8281aa3a4bc562fdc.png">
            </div>
            <div class="s-right">
              <h2 class="title-2"><span>全流程广告管理</span></h2>
              <div>投放前挖掘优质词，投中使用自动规则提效，投后溯源、对比分析广告转化率</div>
              <ul class="font-2">
                <li>
                  <p>投放前：挖优质词定策略</p>
                </li>
                <p class="font-3">自动分析广告历史表现、挖优质词，规划广告投放结构，助力卖家抢量</p>
                <li>
                  <p>投放中：自动规则提效</p>
                </li>
                <p  class="font-3"> 跨广告类型管理，自动规则、广告位锁定等工具高效投放，实时数据优化调整</p>
                <li>
                  <p>投放后：精准透视分析</p>
                </li>
                <p  class="font-3">全局分析广告效果，对比、细分、溯源、透视广告表现，挖掘优质投放策略</p>
              </ul>
            </div>
          </div>
        </div>
        <div class="center3">
          <div class="center3-top">
            我们的优势是什么？
          </div>
          <div class="center3-top1">
            安全高效，简单便捷
          </div>
          <ul class="center3-center">
            <li>
              <div class="center3-img">
                <img src="@/assets/images/intro/group48.png" alt="">
              </div>
              <div class="center3-p">
                <p class="center3-p1">灵活控制订单流程</p>
                <p>订单发货流程自由控制，用户可根据自己的需</p>
                <p>要配置流程，发货环节灵活简便。</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/group49.png" alt="">
              </div>
              <div class="center3-p">
                <p class="center3-p1">成本更可控</p>
                <p>人力成本</p>
                <p>人员流动带来的高额培训成本</p>
                <p>系统成本</p>
                <p>SaaS模式节省80%的系统开发成本</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/group50.png" alt="">
              </div>
              <div class="center3-p">
                <p class="center3-p1">操作更简单</p>
                <p>人性化设计</p>
                <p>根据跨境卖家日常设计系统流程</p>
                <p>智能化处理</p>
                <p>节省80%重复工作的时间</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/group51.png" alt="">
              </div>
              <div class="center3-p">
                <p class="center3-p1">管理更顺畅</p>
                <p>权限管理，规范化每个环节</p>
                <p>运营流程，更加合规、畅通</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/切片3.png" alt="">
              </div>
              <div class="center3-p">
                <p class="center3-p1">数据更安全</p>
                <p>自研亿级调度系统，运行稳定且快速</p>
                <p>数据加密处理，定期漏洞检测，不分享不售卖，从源头保证数据安全，绝不外泄！</p>
              </div>
            </li>
          </ul>
        </div>  
      </div>
      <div class="b-0" v-if="curr_tab.name=='tab2'">
        <div class="vipService">
          <div class="banner">
            <div class="banner-bg" :style="computedStyle_2">
              <!-- <img src="@/assets/images/intro/service-bg.png" alt="" style="width: 100%;height: 460px;"> -->
              <div class="banner-left">
                <div class="text-a">
                  云ERP
                </div>
                <div class="text-b">
                  <span>四款</span>不同的版本功能供你选择
                </div>
                <div class="regsiter-a register-adminssion"   @click="toLogin">
                  立即注册
                </div>
              </div>
              <div class="banner-bottom">
                <ul class="goodsList">
                  <li class="goodsDetail">
                    <p class="text-c">免费版</p>
                    <p class="text-d">适用微小型客户</p>
                    <p class="text-e" style="color: #f73e3e;">免费试用1个月</p>
                    <div>
                      <span class="regsiter-b register-adminssion">立即注册</span>
                      <span class="btn">了解详情</span>
                    </div>
                  </li>
                  <li class="goodsDetail">
                    <p class="text-c">银牌服务</p>
                    <p class="text-d">适用创业型客户</p>
                    <p class="text-e">$3000<span style="color: #a9afc0;">/年</span></p>
                    <div>
                      <span class="regsiter-b register-adminssion">立即注册</span>
                      <span class="btn">了解详情</span>
                    </div>
                  </li>
                  <li class="goodsDetail">
                    <p class="text-c">金牌服务</p>
                    <p class="text-d">适用中大型客户</p>
                    <p class="text-e">$6000<span style="color: #a9afc0;">/年</span></p>
                    <div>
                      <span class="regsiter-b register-adminssion">立即注册</span>
                      <span class="btn">了解详情</span>
                    </div>
                  </li>
                  <li class="goodsDetail" style="border: none">
                    <p class="text-c">铂金服务</p>
                    <p class="text-d">适用于私人定制版</p>
                    <p class="text-e">$9000<span style="color: #a9afc0;">/年</span></p>
                    <div>
                      <span class="regsiter-b register-adminssion">立即注册</span>
                      <span class="btn">了解详情</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tit">
            版本对比
          </div>
          <div class="con">
            <div class="content">

              <div class="tab">
                <div class="row-a">
                  <div class="a-cell1">翰龙电子基础版</div>
                  <div class="a-cell">免费版</div>
                  <div class="a-cell">银牌服务</div>
                  <div class="a-cell">金牌服务</div>
                  <div class="a-cell" style="border-right: 1px solid #d9dde9; width: 188px;">铂金服务</div>
                </div>
                <table class="table" style="text-align: center;">

                  <tbody><tr class="row-b b-cell">
                    <td rowspan="9" class="b-cell" style="width: 260px">基础配置</td>
                    <td style="width: 180px;" class="b-cell">阿里云服务器</td>
                    <td style="width: 180px;" class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">独立数据库</td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">独立域名</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">实时同步更新</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">线上客服支持</td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">本地单独部署</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">订单量</td>
                    <td class="b-cell">限500单/日</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                  </tr>
                  <tr>
                    <td class="b-cell">店铺数</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                  </tr>
                  <tr>
                    <td class="b-cell">操作人数</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                    <td class="b-cell">不限</td>
                  </tr>
                  <tr>
                    <td rowspan="14" class="b-cell">功能模块</td>
                    <td class="b-cell">订单管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">客服管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">统计报表</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">系统配置</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">物流管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">产品管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">采购管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">仓库管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">FBA管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">收款</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">刊登管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">财务管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">广告管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">品牌管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td rowspan="8" class="b-cell">特色功能</td>
                    <td class="b-cell">自动评价</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">自动催付</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">FBA库存管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">自动采购建议</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">多店铺产品管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">业务员提成统计</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">多仓模式管理</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">物流信息追踪</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td rowspan="4" class="b-cell">服务支持</td>
                    <td class="b-cell">现场培训</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell">1次</td>
                    <td class="b-cell">2次</td>
                    <td class="b-cell">5次</td>
                  </tr>
                  <tr>
                    <td class="b-cell">电话支持</td>
                    <td class="b-cell">7*8小时</td>
                    <td class="b-cell">7*12小时</td>
                    <td class="b-cell">7*12小时</td>
                    <td class="b-cell">7*24小时</td>
                  </tr>
                  <tr>
                    <td class="b-cell">即时服务</td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""></td>
                  </tr>
                  <tr>
                    <td class="b-cell">私人定制</td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/wrong.png" alt=""></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""><span>(5人日)</span></td>
                    <td class="b-cell"><img src="@/assets/images/intro/yes.png" alt=""><span>(20人日)</span></td>
                  </tr>
                  <tr>
                    <td rowspan="13" style="width: 260px;" class="b-cell">服务对接</td>
                    <td rowspan="3" class="b-cell" style="width: 180px;">Vendor渠道-API</td>
                    <td class="b-cell">内容</td>
                    <td class="b-cell">方式</td>
                    <td class="b-cell">单价($)</td>
                    <td class="b-cell">首年合计/次年收费15%（$）</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Walmart对接</td>
                    <td class="b-cell">API对接</td>
                    <td class="b-cell">1500</td>
                    <td class="b-cell">1500/225</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Overstack对接</td>
                    <td class="b-cell">API对接</td>
                    <td class="b-cell">2500</td>
                    <td class="b-cell">2500/375</td>
                  </tr>

                  <tr>
                    <td rowspan="7" class="b-cell" style="width: 180px;">Vendor渠道-EDI</td>
                    <td class="b-cell">Amazon VC对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">4500</td>
                    <td class="b-cell">4500/675</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Wayfair对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">2500</td>
                    <td class="b-cell">2500/375</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Home Depot对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">4500</td>
                    <td class="b-cell">4500/675</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Lowes对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">4500</td>
                    <td class="b-cell">4500/675</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Bed bath and Beyond对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">4500</td>
                    <td class="b-cell">4500/675</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Target对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">4500</td>
                    <td class="b-cell">4500/675</td>
                  </tr>
                  <tr>
                    <td class="b-cell">Houzz对接</td>
                    <td class="b-cell">EDI对接</td>
                    <td class="b-cell">4500</td>
                    <td class="b-cell">4500/675</td>
                  </tr>
                  <tr>
                    <td class="b-cell">第三方海外仓</td>
                    <td class="b-cell">第三方海外仓对接</td>
                    <td class="b-cell">API对接</td>
                    <td class="b-cell">1500</td>
                    <td class="b-cell">1500/0</td>
                  </tr>
                  <tr>
                    <td class="b-cell">快递账户</td>
                    <td class="b-cell">快递账户对接</td>
                    <td class="b-cell">API对接</td>
                    <td class="b-cell">1500</td>
                    <td class="b-cell">1500/0</td>
                  </tr>
                  <tr>
                    <td class="b-cell">财务结算订制</td>
                    <td class="b-cell">财务结算订制开发</td>
                    <td class="b-cell">定制化</td>
                    <td class="b-cell">1500</td>
                    <td class="b-cell">1500/0</td>
                  </tr>
                </tbody></table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="b-0" v-if="curr_tab.name=='tab3'">
        <div class="abt">
          <div class="banner">
            <div class="banner-bg" :style="computedStyle_3">
              <!-- <img src="@/assets/images/intro/service-bg.png" alt="" style="width: 100%;height: 460px;"> -->
              <div class="banner-0">
                <div class="text-a">
                  亚马逊跨境电商，用顺衡ERP
                </div>
                <div class="text-b">
                  以一站式跨境电商运营管理系统，助力卖家轻松实现业务增长
                </div>
              </div>
            </div>
          </div>
          <div class="p-0">
            <div class="box-0 top-0">
              <div class="tit-0">
                公司简介
              </div>
              <div class="text-0">
                <p> 南京顺衡电子商务有限公司，致力于为跨境电商卖家提供精细化运营和业财一体化的解决方案。</p>
                <p>公司在外贸与跨境电商领域深耕近10年，拥有完善的跨境电商综合解决经验。</p>
                <p>聚焦亚马逊跨境电商行业，深入研究业务需求和管理痛点，聚焦线下erp线上数字化、广告营销高效化、财务分析精准化、系统数据安全化等方面，
                专注客户为提供一体化智能解决方案，为跨境电商卖家量身打造了最适合的运营和供应链管理系统。</p>
              </div>
            </div>
            <div class="box-0 top-1">
              <div class="tit-0">
                联系我们
              </div>
              <div class="text-0">
                <p> 邮箱地址：shunhengerp@sina.com</p>
                <p>公司地址：江苏省南京市秦淮区水西门大街2号银都锦创广场3A048</p>
              </div>
            </div>
            <div class="img-0 top-2">
              <img class="logo-3" src="@/assets/images/background/公司宣传图.jpg" alt=""> 
            </div>
          </div>
        </div>
      </div>  
      <div  class="wrapPc">
        <div  class="footer-div"><div  class="footer">
          <div  class="footerLogo ">
            <img class="logo-3" src="@/assets/images/icon/切片_白.png" alt=""> <!----> 
            <p  class="top11"> 一站式跨境电商ERP </p>
          </div> 
          <div  class=" aboutDiv" style="width: 460px;">
              <div  class="overflow">
                <div class="left10">联系我们</div>
                <div class="left11 top-0">邮箱地址：shunhengerp@sina.com</div>
                <div class="left11">公司地址：江苏省南京市秦淮区水西门大街2号银都锦创广场3A048</div>
              </div> 
            </div>
          </div>
        </div> 
      </div>   
    </div>
  </div>

</template>


<script setup>

import { ref, onMounted, onUnmounted, computed} from 'vue'
import MyTabs from '@/components/CTabs.vue';
import { useRouter } from 'vue-router';

import backgroundImage from '@/assets/images/background/切片1.png';
import backgroundImage_2 from '@/assets/images/background/service-bg.png';
import backgroundImage_3 from '@/assets/images/background/切片2.png';

const router = useRouter();

const tabs = ref([
  { name: 'tab1', label: '选择顺衡', color: '白' },
  { name: 'tab2', label: 'VIP服务', color: '黑' },
  { name: 'tab3', label: '关于我们', color: '黑' },
]);
const curr_tab = ref(tabs.value[0]);
const handleUpdateData = (data) => {
    // 接收来自子组件的数据
    curr_tab.value = data;
    console.log("---------------------------curr_tab.value", curr_tab.value)
};

const toLogin = () => {
    router.push('/login');
    console.log("---------------------------router", router)
};

// const scrollTo = () => {

// }

// 顶部高度
const scroll_top = ref(0);
const handleScroll = () => {
    scroll_top.value = document.documentElement.scrollTop; // 更新 scrol_top
};

// 背景样式
const computedStyle = computed(() => {
  if (curr_tab.value.color=='白') {
    return {
      background: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
    };
  } else {
    return {}; // 返回一个空对象，表示没有样式
  }
});

const computedStyle_2 = computed(() => {
  return {
    background: `url(${backgroundImage_2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', 
  };
});

const computedStyle_3 = computed(() => {
  return {
    background: `url(${backgroundImage_3})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', 
  };
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll); // 添加滚动事件监听器
});
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll); // 移除监听器以避免内存泄漏
});



</script>


<style scoped>
.main-feild{
  width:100%;
  .body{
    width:100%;
    margin: 0 auto;
    .b-part{
      width:100%;
      height:650px;
    }
    .b-0{
      width:100%;
    }

    #b-p-0{
      width:100%;
      height:auto;
      display: flex;
      flex-direction: column;
      /* justify-content: center;  */
      align-items: center; 
      .top-0{
        width:100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center;  */
        align-items: center; 
      }
      #b-part-0{
        display: flex;
        margin-top: 30px;
        margin-bottom: 80px;
        width:1260px;
        justify-content: space-between; 

        /* flex-direction: row; */
        .sect-left{
          display: flex;                /* 设置为 Flexbox 容器 */
          flex-direction: column;      /* 垂直方向排列子元素 */
          justify-content: flex-end;
          align-items:  flex-start; 
          height:400px;
          .title-1{
            font-size: 52px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 32px;
          }
          .font-1{
            color: #fff;
            font-size: 20px;
            margin-top: 10px;
            margin-bottom: 50px;
            margin-left: 16px;
            li {
              position: relative;
              height:46px;
              line-height: 46px;
            }
          }
          :deep(.el-button--large) {
            height: 50px;
            padding: 12px 26px;
            font-size:22px;
            font-weight: 600;
            background: linear-gradient(to right, rgb(6, 94, 247), #288cf0); 
          }
        }
        .sect-right{
          width: 600px;
          display: flex;
          justify-content: right; 
          img{
            width: 600px;
            
          }
        }
      }
    }
    #b-p-1{
      width:100%;
      height:auto;
      #b-part-1{
        display: flex;
        flex-direction: column;
        /* position: relative; */
        width: 100%;
        align-items: center; 

        .center-title {
          padding: 0 0 50px 0;
          .center1-top {
              color: #333744;
              font-size: 36px;
              font-family: "Microsoft YaHei-Bold";
              padding-top: 70px;
              font-weight: bold;
              text-align: center;
          }
          .center1-top1 {
              margin-top: 20px;
              color: #6b7183;
              font-size: 16px;
              font-family: "Microsoft YaHei";
              text-align: center;
          }
        }
        .center1-1 {
            width: 1260px;
            height: 580px;
            margin: 0 auto;
        }
        .center1-header {
          /* float: left; */
          div {
              display: block;
              unicode-bidi: isolate;
          }
          .fist {
              margin-left: 40px;
          }
          li {
              display: list-item;
              text-align: -webkit-match-parent;
              unicode-bidi: isolate;
          }
          li {
              float: left;
              text-align: center;
              margin-top: 60px;
          }
        }
        .jiantou {
            width: 158px;
            height: 200px;
            float: left;
            text-align: center;
        }
        .jiantou2 {
            width: 18px;
            height: 68px;
            float: right;
            margin-top: 220px;
            margin-right: 100px;
        }
        .jiantou>img {
            margin-top: 120px;
        }
        fieldset, img {
            border: none;
        }
        img {
            overflow-clip-margin: content-box;
            overflow: clip;
        }
        ul {
            display: block;
            list-style-type: disc;
            /* margin-block-start: 1em;
            margin-block-end: 1em; */
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
            unicode-bidi: isolate;
        }
        ul, ol {
            list-style: none;
        }
      }
    }

    #b-p-2{
      width:100%;
      height:auto;
      background-color: rgba(246, 248, 251, 1);
      .center1-header {
        display:flex;
        flex-wrap:wrap;
        /* float: left; */
      }
      .center-title {
        padding: 0 0 60px 0;
        .center1-top {
            color: #333744;
            font-size: 36px;
            font-family: "Microsoft YaHei-Bold";
            padding-top: 50px;
            /* font-weight: bold; */
            text-align: center;
        }
        .center1-top1 {
            margin-top: 14px;
            color: #6b7183;
            font-size: 16px;
            font-family: "Microsoft YaHei";
            text-align: center;
        }
      }
      ul {
          display: block;
          list-style-type: disc;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          /* padding-inline-start: 40px; */
          unicode-bidi: isolate;
      }
      ul{
        li {
            display: list-item;
            text-align: -webkit-match-parent;
            unicode-bidi: isolate;
        }
        li {
            float: left;
            text-align: center;
        }
        .margin-0{
          margin-top: 60px;
        }
      }
      ul{
        list-style: none;
      }
      .center1-1 {
        width: 1260px;
        height: 530px;
        margin: 0 auto;
        ._文本段落 {
          text-align: left;
        }
        .u17 {
          width:420px;
          display: flex;
          justify-content: center;
        }
        .u18_div {
            display: flex;
            justify-content: flex-start;
            border-width: 0px;
            width: 240px;
            height: 88px;
            background: inherit;
            background-color: rgba(255, 255, 255, 0);
            border: none;
            border-radius: 0px;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            line-height: 28px;
            .u19_img{
              height: 70px;
            }
        }
        .text {           
          align-self: flex-start;
          justify-self: flex-start;
          padding: 0px 0px 0px 0px;
          box-sizing: border-box;
          width: 240px;
          padding-left: 10px;
        }
        .ax_default {
            font-family: 'Arial Normal', 'Arial';
            font-weight: 400;
            font-style: normal;
            /* font-size: 13px; */
            letter-spacing: normal;
            color: #333333;
            vertical-align: none;
            text-align: left;
            line-height: normal;
            text-transform: none;
        }
      }
    }
    .b-p-3{
      width:100%;
      display: flex;
      justify-content: center;
      .s-body{
        padding-left: 20px;
        margin-top: 90px;
        width: 1230px;
        /* height: 500px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left-top-1{
          margin-top: 40px;
        }
        .left-top-2{
          margin-top: 0px;
          margin-bottom:20px;
        }
        .s-left{
          height:420px;
          .title-2{
            font-size: 36px;
            color: #333;
            font-weight: 400;
            margin-bottom: 10px;
          }
          .font-2{
            color: #333;
            font-size: 14px;
            margin-top: 40px;
            margin-bottom: 50px;
            margin-left: 16px;
            width:480px;
            li {
              /* position: relative; */
              margin-top: 20px;
              height:24px;
              line-height: 24px;
            }
          }
          .font-3{
            color: #666;
          }
        }
        .s-right{
          .u19_img{
            height:400px;
          }
          .u19_img0{
            height:330px;
          }
        }
      }
    }
    .b-p-4{
      width:100%;
      display: flex;
      justify-content: center;
      background-color: rgba(246, 248, 251, 1);
      .s-body{
        padding-left: 20px;
        margin-top: 90px;
        width: 1230px;
        /* height: 600px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .s-left{
          .u19_img{
            height:400px;
          }
          .u19_img0{
            height:380px;
          }
        }
        .s-right{
          margin-top: 50px;
          height:420px;
          .title-2{
            font-size: 36px;
            color: #333;
            font-weight: 400;
            margin-bottom: 10px;
          }
          .font-2{
            color: #333;
            font-size: 14px;
            margin-top: 40px;
            margin-bottom: 50px;
            margin-left: 16px;
            width:470px;
            li {
              /* position: relative; */
              margin-top: 20px;
              height:24px;
              line-height: 24px;
            }
          }
          .font-3{
            color: #666;
          }
        }
      }
    }
    .center3 {
      /* width: 1200px; */
      height: 580px;
      background-color: skyblue;
      margin: 0 auto;
      background-image: url(@/assets/images/intro/group52.png);

      .center3-top {
        width: 300px;
        margin: 0 auto;
        color: #ffffff;
        font-size: 30px;
        font-family: "Microsoft YaHei-Bold";
        padding-top: 50px;
        font-weight: bold;
      }
      .center3-top1 {
          width: 200px;
          margin: 0 auto;
          margin-top: 20px;
          color: #6b7183;
          font-size: 16px;
          font-family: "Microsoft YaHei";
      }
      .center3-img>img {
          width:126px;
          margin-top: 60px;
      }
      .center3-img {
          width: 235px;
          height: 210px;
      }
      .center3-p {
          text-align: center;
          font-family: "Microsoft YaHei";
          font-size: 12px;
          color: #969db3;
      }
      .center3-p p {
        line-height: 18px;
        margin-left: 14px;
        margin-right: 14px;
      }
      .center3-p1 {
          font-family: "Microsoft YaHei";
          font-size: 16px;
          margin-bottom: 15px;
          color: #2e313d;
          font-weight: 600;
      }
    }
    .center3-center {
        width: 1274px;
        height: 350px;
        margin: 0 auto;
        margin-top: 30px;
        li {
            width: 235px;
            height: 350px;
            background-color: #ffffff;
            float: left;
            text-align: center;
        }
        .mar-0{
          margin-left: 23px;
        }
    }

    .wrapPc {
        min-width: 1260px;
        display: block;
        .footer-div {
            margin: 0 auto;
            background-color: #213360;
            padding-bottom: 46px;
            position: relative;
            z-index: 999;
        }
        .footer {
          margin: 0 auto;
          overflow: hidden;
          padding-top: 36px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          width: 1260px;
        }
        .footerLogo {
          margin-top: 14px;
          margin-left: 100px;
          width: 480px;
          display: flex;
          flex-direction: row;
          .logo-3{
            height:44px;
          }
        }
        .footerLogo p {
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 26px;
            color: #fff;
        }
        .top10 {
            margin-top: 10px;
        }
        .top11 {
            margin-top: 12px;
            margin-left: 30px;
            font-size: 24px;
        }
        .left10 {
            font-size: 14px;
            color: #fff;
            font-weight: 400;
        }
        .left11 {
            font-size: 12px;
            color: #fff;
            font-weight: 400;
            height: 20px;
        }
        .top-0{
            margin-top:6px;
        }
        .overflow {
            overflow: hidden;
        }
        ul, ol {
            list-style: none;
        }
    }


    .vipService{
      .banner {
          width: 100%;
          .banner-bg {
              width: 100%;
              height: 650px;
              margin: 0 auto;
              /* margin-top: 70px; */
              /* background-color: #f6f7fc; */
              position: relative;
          }
          .banner-left {
              position: absolute;
              width: 500px;
              height: 190px;
              font-family: "Microsoft YaHei";
              font-size: 32px;
              color: #fffefe;
              font-weight: bold;
              top: 66px;
              margin-left: 25%;
              .text-b {
                  margin-top: 15px;
                  span {
                      color: #4792e0;
                  }
              }
              .regsiter-a {
                  width: 120px;
                  height: 40px;
                  border-radius: 20px;
                  background-color: #4792e0;
                  color: #ffffff;
                  font-family: "Microsoft YaHei";
                  font-size: 18px;
                  margin-top: 30px;
                  line-height: 40px;
                  text-align: center;
                  font-weight: normal;
                  cursor: pointer;
              }
          }
          .banner-bottom {
              position: absolute;
              width: 1200px;
              height: 280px;
              background-color: #fff;
              border: 1px solid #d9dde9;
              left: 50%;
              margin-left: -600px;
              top: 330px;
              ul, ol {
                  list-style: none;
              }
              .goodsDetail {
                  float: left;
                  width: 299px;
                  height: 260px;
                  text-align: center;
                  margin-top: 8px;
                  font-family: "Microsoft YaHei";
                  border-right: 1px solid #edeff6;
                  .text-c {
                      font-size: 22px;
                      color: #2e313d;
                      margin-top: 20px;
                  }
                  .text-d {
                      font-size: 14px;
                      color: #6b7183;
                      margin-top: 5px;
                  }
                  .text-e {
                      font-size: 26px;
                      color: #333744;
                      margin-top: 30px;
                  }
              }
          }
      }
      .tit {
          width: 100%;
          height: 80px;
          margin: 0 auto;
          text-align: center;
          font-family: "Microsoft YaHei";
          font-size: 30px;
          color: #2e313d;
          line-height: 80px;
      }
      .con {
          width: 100%;
          .content {
              width: 100%;
              background-color: #f7f8fc;
              margin: 0 auto;
              padding-top: 30px;
              padding-bottom: 30px;
              .tab {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  /* justify-content: center; */
                  align-items: center;
                  .row-a {
                      width: 1200px;
                      height: 50px;
                      /* margin: 0 auto; */
                      display: flex;
                      flex-direction: row;
                      .a-cell1 {
                          width: 449px;
                          height: 50px;
                          border: 1px solid #d9dde9;
                          border-bottom: none;
                          border-right: none;
                          background-color: #f2f4fb;
                          font-family: "Microsoft YaHei";
                          font-size: 18px;
                          color: #2e313d;
                          float: left;
                          text-align: center;
                          line-height: 50px;
                      }
                      .a-cell {
                          /* width: 186px; */
                          flex: 1; 
                          height: 50px;
                          border: 1px solid #d9dde9;
                          border-bottom: none;
                          border-right: none;
                          background-color: #f2f4fb;
                          font-family: "Microsoft YaHei";
                          font-size: 18px;
                          color: #2e313d;
                          float: left;
                          text-align: center;
                          line-height: 50px;
                      }
                  }
                  .table {
                      width: 1200px;
                      height: 50px;
                      /* margin: 0 auto; */
                  }
                  table {
                      /* border-collapse: collapse; */
                      border-spacing: 0;
                  }
                  .row-b {
                      width: 1200px;
                      height: 40px;
                  }
                  .b-cell {
                      width: 184px;
                      height: 40px;
                      font-family: "Microsoft YaHei";
                      font-size: 14px;
                      color: #6b7183;
                      border: 1px solid #d9dde9;
                  }
              }
          }
      }
    }
    .abt{
      .banner {
          width: 100%;
          /* margin-bottom:60px; */
          .banner-bg {
              width: 100%;
              height: 510px;
              /* margin: 0 auto; */
              /* margin-top: 70px; */
              /* background-color: #fcf9f6; */
              /* position: relative; */
              display: flex;
              /* flex-direction: column; */
              justify-content: center;

          }
          .banner-0 {
              width: 1000px;
              height: 190px;
              font-family: "Microsoft YaHei";
              color: #fffefe;
              top: 66px;
              display: flex;
              flex-direction: column;
              align-items:  center;
              margin-top: 200px;
              .text-a {
                font-size: 48px;
                font-weight: bold;
              }
              .text-b {
                  margin-top: 15px;
                  font-size: 28px;

              }
          }
      }
      .p-0{
        width: 100%;
        .box-0{
          /* margin-left:140px; */
          width: 100%;
          height:200px;
          color: #333;
          display: flex;
          flex-direction:column;
          align-items: center;
          .tit-0{
            width:1240px;
            font-size: 38px;
            margin-bottom:14px;
          }
          .text-0{
            width:1240px;
            font-size: 14px;
            line-height:30px;
          }
        }
        .img-0{
          width:100%;
          display: flex;
          justify-content: center;
          .logo-3{
            width:1240px;
            border-radius: 16px; /* 圆形 */
            /* object-fit: cover;  */
          }
        }
        .top-0{
          margin-top:110px;
        }
        .top-1{
          margin-top:70px;
        }
        .top-2{
          margin-top:14px;
          margin-bottom:110px;
        }
      }



    }


    .b-color-0{
      background-color: bisque;
    }
    .b-color-1{
      background-color:gainsboro;
    }
  }

  .font-size-0{
    font-size: 16px!important; 
  }
}

.s-0{
  background-color: #fff;
}
.s-1{
  background-color: transparent;

}
.header{
  position: sticky;
  top: 0;          
  left: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;    /* 垂直居中 */
  --tw-bg-opacity: 1;
  /* box-shadow: 0 2px 10px 0 rgba(180, 189, 221, .25); */

  .nav-logo{
    display: inline-block;
    margin-right: 490px;
    .logo-0{
      height:44px;
    }
    .logo-1{
      height:42px;
    }
  }
  .nav{
    width:540px;
  }
  .function-area{
    margin-left: 90px;
    cursor: pointer;
    .font-0{
      color:#000000;
    }
    .font-1{
      color:#ffffff;
    }
  }
}


:deep(.tab.active){
  color: #fff;

}



</style>
